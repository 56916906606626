<template>
  <vue-perfect-scrollbar
    :settings="{ suppressScrollX: true, wheelPropagation: false }"
    class="h-100 rtl-ps-none ps scroll"
    style="height: 100%;"
  >
    <div class="pa-5 relative">
      <v-app-bar class="pt-1" fixed flat>
        <div class="d-flex justify-space-between align-center w-full">
          <h6 class="ma-0">{{ $t("app.notifications") }}</h6>
          <slot name="notificationDrawerCloseButton"></slot>
        </div>
      </v-app-bar>
    </div>

    <div class="pa-5 mt-10">
      <div v-for="(item, index) in items" :key="index">
        <div class="d-flex justify-space-between mb-8">
          <div class="d-flex">
            <!--            <v-avatar size="40" class="mr-2" :color="item.iconColor">-->
            <!--              <v-icon :class="item.iconColorText">{{ item.iconName }}</v-icon>-->
            <!--            </v-avatar>-->
            <div>
              <p :class="[item.is_read ? 'text-muted' : '']" class="font-weight-medium body-2 ma-0">
                {{ item.title }}
              </p>

              <p :class="[item.is_read ? 'text-muted' : '']" class="font-weight-light body-2 ma-0">
                <ca-text-truncator :text="item.message" :max-width="150" overflow-sign="" />
                <router-link class="ml-2" :to="{ name: 'notifications', hash: '#' + item.id }"
                  ><v-chip x-small style="cursor: pointer"
                    ><v-icon size="24px">mdi-dots-horizontal</v-icon></v-chip
                  ></router-link
                >
              </p>
            </div>
          </div>
          <div>
            <span class="text--disabled caption">{{ item.date }}</span>
          </div>
        </div>
        <v-divider class="py-2"></v-divider>
      </div>
    </div>
  </vue-perfect-scrollbar>
</template>
<script>
import CaTextTruncator from "../../views/components/TextTruncator";
export default {
  components: { CaTextTruncator },
  data() {
    return {
      items: []
    };
  },
  mounted() {
    this.loadLastMessages();
  },
  methods: {
    loadLastMessages() {
      this.axios.get("/messages/last").then(response => {
        this.items = response.data.data;
      });
    }
  }
};
</script>
